import { GRANT_LIST } from "constants/actionTypes";
import { SHOW_GRANT } from "constants/actionTypes";
import { LOAD_FORM_GRANT } from "constants/actionTypes";
import { EDIT_GRANT, NEW_GRANT, CLEAN_NEW_GRANT_AMBASSADOR, DELETE_GRANT } from "constants/actionTypes";
import { UPLOAD_IMAGE } from "constants/actionTypes";
import { GET_PROJECT_PROGRESS } from "constants/actionTypes";
import { MBS_IMAGE_ALERT, DELETE_IMAGE_ALERT } from "constants/actionTypes";
import { adjustDate } from "assets/functions/general.jsx";
import { NEW_GRANT_UPDATE } from "constants/actionTypes";
import { SHOW_GRANT_UPDATE } from "constants/actionTypes";
import { GRANT_ACTIVE_LIST } from "constants/actionTypes";
import { NEW_GRANT_AMBASSADOR } from "constants/actionTypes";
import { EDIT_GRANT_AMBASSADOR } from "constants/actionTypes";
import { LOAD_FORM_GRANT_AMBASSADOR } from "constants/actionTypes";
import { SHOW_GRANT_AMBASSADOR } from "constants/actionTypes";
import { GRANT_AMBASSADOR_LIST } from "constants/actionTypes";
import { SEND_REVISION_GRANT_AMBASSADOR } from "constants/actionTypes";
import { SEND_CORRECTION_GRANT_AMBASSADOR } from "constants/actionTypes";
import { SEND_APPROVED_GRANT_AMBASSADOR } from "constants/actionTypes";
import { SEND_REJECT_GRANT_AMBASSADOR } from "constants/actionTypes";
import { SHOW_GRANT_DEADLINE } from "constants/actionTypes";
import { GRANT_AMBASSADOR_APPLICATION } from "constants/actionTypes";
import { NEW_GRANT_GROUP } from "constants/actionTypes";
import { LOAD_FORM_GRANT_GROUP } from "constants/actionTypes";
import { SHOW_GRANT_GROUP } from "constants/actionTypes";
import { SHOW_GRANT_GROUP_LIST } from "constants/actionTypes";
import { SHOW_GRANT_USER } from "constants/actionTypes";
import { SHOW_GRANT_STATISTIC } from "constants/actionTypes";
import { SHOW_LIST_GRANT_AMBASSADOR } from "constants/actionTypes";
import { SHOW_GRANT_GRADUATES } from "constants/actionTypes";

import moment from "moment";
import { MENTORED_AMBASSADOR_LIST } from "constants/actionTypes";
import { MENTORED_AMBASSADOR } from "constants/actionTypes";
import { SHOW_REQUIREMENTS } from "constants/actionTypes";
import { SHOW_APPROVED_APPLICATIONS } from "constants/actionTypes";
import { SHOW_REJECTED_APPLICATIONS } from "constants/actionTypes";
import { LOAD_FORM_GRANT_REQUIREMENT } from "constants/actionTypes";
import { SHOW_GRANT_REQUIREMENT } from "constants/actionTypes";
import { GRANT_REQUIREMENT_LIST } from "constants/actionTypes";
import { GRANTMEMBER_LIST } from "constants/actionTypes";
import { CLEAN_GRANTMEMBER_LIST } from "constants/actionTypes";
import { CLEAN_SHOW_GRANT_AMBASSADOR } from "constants/actionTypes";
import { ERROR_ADD_GRANTMEMBER } from "constants/actionTypes";
import { MEMBER_ALREADY_ADDED } from "constants/actionTypes";
import { CLEAN_ADD_MEMBER_WARNING } from "constants/actionTypes";
import { SHOW_AMBASSADOR_RECOGNITION } from "constants/actionTypes";
import { AMBASSADOR_LEVEL } from "constants/actionTypes";

const initialState = {
  error_add_grantmember: false,
  member_already_added: false,
  show_grant: {
    id: "",
    administrator: [],
    grantambassador: [],
    date: "",
    title: "",
    language: "",
    description: "",
    state: "",
    type: "state.growth",
  },
  show_grant_update: [],
  show_grant_ambassador: {
    id: "",
    administrator: [],
    mentoredambassador: {
      first_name: "",
      last_name: ""
    },
    ambassador: {
      country: "USA"
    },
    grant: [],
    number: "",
    state: "",
    code: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question12: "state.not",
    file: "",
  },
  new_grant: {
    id: "",
    language: "en",
    state: "state.available",
    type: "state.growth",
    description: ""
  },
  initial_grant: {
    id: "",
    language: "en",
    state: "state.available",
    type: "state.growth",
    deadline_date: moment().endOf('month').format('YYYY-MMM-DD'),
    description: ""
  },
  new_grant_requirement: {
    active_time: 0,
    receipt_time: 0,
    mbs_graduates: 0,
    sa_graduates: 0,
    nsa_graduates: 0,
    country: "-1",
    language: "en",
    type: "state.growth",
    initial_date: moment('2018-01-01'),
  },
  show_grant_requirement: {
    activeTime: 0,
    receiptTime: 0,
    mbsGraduates: 0,
    saGraduates: 0,
    nsaGraduates: 0,
    country: "",
    language: "en",
    type: "state.growth",
  },
  grant_requirement_list: [],
  new_grant_ambassador: {
    id: "",
    number: "",
    amount: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
    question9: "",
    question10: "",
    question11: "",
    question12: "state.not",
    question13: "",
    question14: "",
    question15: "",
    code: "",
  },
  edit_grant_ambassador: {
    id: "",
  },
  correction_grant: {
    correction: ""
  },
  grant_list: [],
  grantmember_list: [],
  grant_active_list: [],
  grant_ambassador_list: [],
  grant_program: [],
  progress_list: {
    progressMbs: [" "],
    progressSa: [" "]
  },
  grant_group: {
    groups: { "g-220": true }
  },
  show_grant_user: [],
  show_grant_statistic: {
    grant_list: [],
    total_list: {
      total_amount: 0,
      total_groups: 0,
      total_participants: 0,
      total_applications: 0,
    }
  },
  show_grant_group: [],
  show_list_grant_ambassador: [],
  grant_deadline: new Date(),
  loading: true,
  image_alert: false,
  grant_graduates_list: [],
  mentored_ambassador_list: [],
  mentored_ambassador: [],
  mentored_ambassador_initial: {},
  show_requirements: {
    ambassador_active_time: {
      months: 0,
      approval_date: "",
    },
    last_growth_approved_grant: {
      months: 0,
      approval_date: "",
    },
    growth_active_time: 0,
    growth_receipt_time: 0,
    growth_missing_graduates: 0,
    all_time_growth_grants: "",
    application_under_revision: "",
    success_ambassador_months: "",
    graduates_individual: "",
    total_groups: "",
    last_six_months_grant: "more_than_6_months",
    last_four_months_grant: "more_than_4_months",
    alumni_with_20_graduates: "",
    sa_alumni: "",
    
    growth_total_graduates: 0,
    growth_minimum_graduates: 0,
    
    growth_initial_date: "2018-01-01",
    all_time_scholarship_grants: 0,
    scholarship_total_graduates: 0,
    scholarship_minimum_graduates: 0,
    scholarship_missing_graduates: 0,
    scholarship_receipt_time: 0,
    last_scholarship_approved_grant: {
        months: 0
    },
    scholarship_active_time: 0,
    scholarship_initial_date: "2018-01-01",
    incentive_active_time: 0,
    incentive_nsa_graduates: 0,
    incentive_initial_date: "2018-01-01",
    incentive_total_graduates: 0,
    incentive_minimum_graduates: 0,
},
  approved_application_list: [],
  rejected_application_list: [],
  show_ambassador_recognition: [],
  ambassador_level: "",
}

export const grantReducer = (state = initialState, action) => {
  switch (action.type) {
    case GRANT_LIST:
      return Object.assign({}, state, {
        grant_list: action.payload,
        loading: false
      });
    case SHOW_GRANT:
      return Object.assign({}, state, {
        show_grant: action.payload
      });
    case LOAD_FORM_GRANT:
      const grant = action.data;
      grant.date = adjustDate(grant.date);

      return Object.assign({}, state, {
        data: grant
      });
    case EDIT_GRANT:
      return Object.assign({}, state, {
        edit_grant: action.payload
      });
    case NEW_GRANT:
      return Object.assign({}, state, {
        new_grant: action.payload
      });
    case DELETE_GRANT:
      return Object.assign({}, state, {
        delete_grant: action.payload
      });
    case GET_PROJECT_PROGRESS:
      return Object.assign({}, state, {
        progress_list: action.payload,
        loading: false,
      });
    case MBS_IMAGE_ALERT:
      return Object.assign({}, state, {
        id_student: action.payload,
        image_alert: true,
      });
    case UPLOAD_IMAGE:
      return Object.assign({}, state, {
        upload_image: action.payload,
      });
    case DELETE_IMAGE_ALERT:
      return Object.assign({}, state, {
        image_alert: false,
      });
    case NEW_GRANT_UPDATE:
      return Object.assign({}, state, {
        new_grant_update: action.payload
      });
    case SHOW_GRANT_UPDATE:
      return Object.assign({}, state, {
        show_grant_update: action.payload,
        loading: false
      });
    case GRANT_ACTIVE_LIST:
      return Object.assign({}, state, {
        grant_active_list: action.payload,
        loading: false
      });
    case NEW_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        edit_grant_ambassador: action.payload,
        new_grant_ambassador: action.payload
      });

    case CLEAN_NEW_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        new_grant_ambassador: initialState.new_grant_ambassador
      });
    case SHOW_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        show_grant_ambassador: action.payload,
        loading: false
      });
    case EDIT_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        edit_grant_ambassador: action.payload
      });
    case LOAD_FORM_GRANT_AMBASSADOR:
      const ambassador_grant = action.data;
      if (ambassador_grant.mentoredambassador != undefined) {
        ambassador_grant.mentoredambassador = ambassador_grant.mentoredambassador.id.toString();
      }
      return Object.assign({}, state, {
        data_grant_ambassador: ambassador_grant
      });
    case GRANT_AMBASSADOR_LIST:
      return Object.assign({}, state, {
        grant_ambassador_list: action.data,
        loading: false
      });
    case SEND_REVISION_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        edit_grant_ambassador: action.data
      });
    case SEND_REJECT_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        edit_grant_ambassador: action.data
      });
    case SEND_APPROVED_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        edit_grant_ambassador: action.data
      });
    case SEND_CORRECTION_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        edit_grant_ambassador: action.data
      });
    case SHOW_GRANT_DEADLINE:
      return Object.assign({}, state, {
        grant_deadline: action.payload
      });
    case GRANT_AMBASSADOR_APPLICATION:
      return Object.assign({}, state, {
        grant_ambassador_application: action.data
      });
    case NEW_GRANT_GROUP:
      return Object.assign({}, state, {
        new_grant_group: action.data
      });
    case LOAD_FORM_GRANT_GROUP:
      var data = {};
      var options = {};
      if (action.data.length != undefined) {
        action.data.forEach(element => {
          options["g-" + element.id] = true;
        });
        data.groups = options;
      }
      return Object.assign({}, state, {
        grant_group: data
      });
    case SHOW_GRANT_USER:
      return Object.assign({}, state, {
        show_grant_user: action.payload
      });
    case SHOW_LIST_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        show_list_grant_ambassador: action.payload
      });
    case SHOW_GRANT_STATISTIC:
      return Object.assign({}, state, {
        show_grant_statistic: action.data
      });
    case SHOW_GRANT_GROUP_LIST:
      return Object.assign({}, state, {
        show_grant_group: action.data
      });
    case SHOW_GRANT_GRADUATES:
      return Object.assign({}, state, {
        grant_graduates_list: action.payload,
        loading: false
      });
    case SHOW_REQUIREMENTS:
      return Object.assign({}, state, {
        show_requirements: action.payload,
      });
    case MENTORED_AMBASSADOR_LIST:
      return Object.assign({}, state, {
        mentored_ambassador_list: action.payload,
        loading: false
      });
    case MENTORED_AMBASSADOR:
      return Object.assign({}, state, {
        mentored_ambassador: action.payload,
        loading: false
      });
    case SHOW_APPROVED_APPLICATIONS:
      return Object.assign({}, state, {
        approved_application_list: action.payload,
        loading: false
      });

    case SHOW_REJECTED_APPLICATIONS:
      return Object.assign({}, state, {
        rejected_application_list: action.payload,
        loading: false
      });
    case GRANT_REQUIREMENT_LIST:
      return Object.assign({}, state, {
        grant_requirement_list: action.payload,
        loading: false
      });
    case SHOW_GRANT_REQUIREMENT:
      return Object.assign({}, state, {
        show_grant_requirement: action.payload,
      });
    case LOAD_FORM_GRANT_REQUIREMENT:
      var grant_requirement = action.data;
      return Object.assign({}, state, {
        data: grant_requirement
      });
    case GRANTMEMBER_LIST:
      return Object.assign({}, state, {
        grantmember_list: action.payload,
        loading: false
      });
    case CLEAN_GRANTMEMBER_LIST:
      return Object.assign({}, state, {
        grantmember_list: [],
      });
    case CLEAN_SHOW_GRANT_AMBASSADOR:
      return Object.assign({}, state, {
        show_grant_ambassador: initialState.show_grant_ambassador,
      });
    case ERROR_ADD_GRANTMEMBER:
      return Object.assign({}, state, {
        error_add_grantmember: true,
      });
    case MEMBER_ALREADY_ADDED:
      return Object.assign({}, state, {
        member_already_added: true,
      });
    case CLEAN_ADD_MEMBER_WARNING:
      return Object.assign({}, state, {
        member_already_added: false,
        error_add_grantmember: false,
      });
    case SHOW_AMBASSADOR_RECOGNITION:
      return Object.assign({}, state, {
        show_ambassador_recognition: action.payload,
        loading: false
      });
    case AMBASSADOR_LEVEL:
      return Object.assign({}, state, {
        ambassador_level: action.payload
      })
  }
  return state;
}