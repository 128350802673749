export const SET_DATA = 'SET_DATA';
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const DATA_LOADED = 'DATA_LOADED';
export const DATA_REQUESTED = 'DATA_REQUESTED';
 
// Authentication Constants 
export const SUCCESSFULL_AUTHENTICATION = 'SUCCESSFULL_AUTHENTICATION';
export const FAILED_AUTHENTICATION = 'FAILED_AUTHENTICATION';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CLEAN_AUTHENTICATION = 'CLEAN_AUTHENTICATION';

// Constants of Programmbs
export const SHOW_PROGRAMMBS="SHOW_PROGRAMMBS";
export const UPDATE_REVISION_PROGRAMMBS="UPDATE_REVISION_PROGRAMMBS";
export const LOAD_FORM_PROGRAMMBS="LOAD_FORM_PROGRAMMBS";
export const LOAD_FORM_PROGRAMMBS_VALIDATION="LOAD_FORM_PROGRAMMBS_VALIDATION";
export const SHOW_PROGRAMMBS_VALIDATION="SHOW_PROGRAMMBS_VALIDATION";
export const ERROR_EDIT_REVISION="FAILED_EDIT_REVISION";
export const SUCCESSFULL_EDIT_REVISION="SUCCESSFULL_EDIT_REVISION";
export const HIDE_REVISION_ALERT="HIDE_REVISION_ALERT";
export const SUCCESSFULL_APPROVE_PROJECT="SUCCESSFULL_APPROVE_PROJECT";
export const ERROR_APPROVE_PROJECT="ERROR_APPROVE_PROJECT";
export const SUCCESSFULL_SEND_REVISION_PROJECT="SUCCESSFULL_SEND_REVISION_PROJECT";
export const ERROR_SEND_REVISION_PROJECT="ERROR_SEND_REVISION_PROJECT";
export const ACTIVE_TAB="ACTIVE_TAB";
export const RESET_SHOW_PROGRAMMBS="RESET_SHOW_PROGRAMMBS";
export const DEFAULT_VALUES_PROGRAMMBS="DEFAULT_VALUES_PROGRAMMBS";
export const VIDEO_PROMOTION="VIDEO_PROMOTION";
export const PROJECT_LIST = "PROJECT_LIST";
export const MBS_PROJECT_LIST = "MBS_PROJECT_LIST";
export const SA_PROJECT_LIST = "SA_PROJECT_LIST";
export const SUCCESSFUL_NEW_POST_EVALUATION = "SUCCESSFUL_NEW_POST_EVALUATION";
export const SHOW_PROGRAMMBS_PROGRESS = "SHOW_PROGRAMMBS_PROGRESS";
export const PAGINATED_PROGRAMMBS_LIST = "PAGINATED_PROGRAMMBS_LIST";
export const LOADING_PROGRAMMBS = "LOADING_PROGRAMMBS";
export const RESET_PAGINATED_PROGRAMMBS_LIST = "RESET_PAGINATED_PROGRAMMBS_LIST";

// Constants of Mbs Express
export const SHOW_EXPRESS_PROGRESS = "SHOW_EXPRESS_PROGRESS";
export const SHOW_EXPRESS = "SHOW_EXPRESS";

// Constants of Programsa
export const SHOW_PROGRAMSA="SHOW_PROGRAMSA";
export const UPDATE_REVISION_PROGRAMSA="UPDATE_REVISION_PROGRAMSA";
export const LOAD_FORM_PROGRAMSA="LOAD_FORM_PROGRAMSA";
export const SHOW_PILOT_GROUP_LIST="SHOW_PILOT_GROUP_LIST";
export const LOAD_FORM_PILOT_GROUP="LOAD_FORM_PILOT_GROUP";
export const NEW_PILOT_GROUP="NEW_PILOT_GROUP";
export const SHOW_PROGRAMSA_PROGRESS = "SHOW_PROGRAMSA_PROGRESS";
export const PAGINATED_PROGRAMSA_LIST = "PAGINATED_PROGRAMSA_LIST";
export const LOADING_PROGRAMSA = "LOADING_PROGRAMSA";
export const RESET_PAGINATED_PROGRAMSA_LIST = "RESET_PAGINATED_PROGRAMSA_LIST";

// Constants of Student
export const STUDENT_LIST="STUDENT_LIST";
export const MBS_STUDENT_LIST="MBS_STUDENT_LIST";
export const SHOW_STUDENT="SHOW_STUDENT";
export const LOAD_FORM_STUDENT="LOAD_FORM_STUDENT";
export const EDIT_STUDENT="EDIT_STUDENT";
export const NEW_STUDENT="NEW_STUDENT";
export const ERROR_EDIT_STUDENT="ERROR_EDIT_STUDENT";
export const DELETE_STUDENT="DELETE_STUDENT";
export const EDIT_PASSWORD_STUDENT="EDIT_PASSWORD_STUDENT"
export const GET_STUDENT_AMBASSADOR="GET_STUDENT_AMBASSADOR"
export const SUCCESS_STORY="SUCCESS_STORY"
export const SEARCH_LOADING="SEARCH_LOADING";
export const INACTIVE_STUDENT_LIST="INACTIVE_STUDENT_LIST";
export const AVAILABLE_COURSE_LIST="AVAILABLE_COURSE_LIST";
export const UNIT_STUDENT_LIST="UNIT_STUDENT_LIST";
export const NEW_PROGRESS="NEW_PROGRESS";
export const EDIT_PROGRESS="EDIT_PROGRESS";
export const ENDROLLED_COURSE_PARTICIPANT_LIST="ENROLLED_COURSE_PARTICIPANT_LIST";
export const UPDATE_PARTICIPANT_NAME="UPDATE_PARTICIPANT_NAME";
export const GROUP_STUDENT_LIST="GROUP_STUDENT_LIST";
export const PAGINATED_STUDENT_LIST="PAGINATED_STUDENT_LIST";
export const UPDATE_STUDENT_PAGINATION="UPDATE_STUDENT_PAGINATION";
export const LOADING_STUDENT="LOADING_STUDENT";
export const PAGINATED_SUCCESS_STORY_LIST = "PAGINATED_SUCCESS_STORY_LIST";
export const PAGINATED_POTENTIALSA_LIST="PAGINATED_POTENTIALSA_LIST";
export const PAGINATED_STUDENT_GRANTMEMBER_LIST="PAGINATED_STUDENT_GRANTMEMBER_LIST";
export const UPDATE_STUDENT_GRANTMEMBER_PAGINATION="UPDATE_STUDENT_GRANTMEMBER_PAGINATION";
export const PAGINATED_STUDENT_AMBASSADOR_LIST="PAGINATED_STUDENT_AMBASSADOR_LIST";

// Constants of User
export const SHOW_USER="SHOW_USER";
export const LOAD_FORM_USER="LOAD_FORM_USER";
export const EDIT_USER="EDIT_USER";
export const DELETE_USER="DELETE_USER";
export const EDIT_PASSWORD_USER="EDIT_PASSWORD_USER";
export const ERROR_EDIT_USER="ERROR_EDIT_USER";
export const SUCCESSFULL_ACTIVE_USER="SUCCESSFULL_ACTIVE_USER";
export const NEW_PASSWORD="NEW_PASSWORD";
export const ERROR_GMAIL="ERROR_GMAIL";
export const ALREADY_EXIST_USER="ALREADY_EXIST_USER";
export const USER_NOT_FOUND="USER_NOT_FOUND";
export const MESSAGE_SENDED="MESSAGE_SENDED";
export const SUCCESSFUL_EMAIL_OTP="SUCCESSFUL_EMAIL_OTP";
export const ERROR_EMAIL_OTP="ERROR_EMAIL_OTP";
export const SUCCESSFUL_WHATSAPP_OTP="SUCCESSFUL_WHATSAPP_OTP";
export const ERROR_WHATSAPP_OTP="ERROR_WHATSAPP_OTP";
export const SUCCESSFUL_PHONE_OTP="SUCCESSFUL_PHONE_OTP";
export const ERROR_PHONE_OTP="ERROR_PHONE_OTP";
export const RESET_OTP_VARIABLES="RESET_OTP_VARIABLES";
export const RESPONSE_OTP_RESETPASSWORD="RESPONSE_OTP_RESETPASSWORD";
export const AVATAR_AUTHENTICATION_WARNING="AVATAR_AUTHENTICATION_WARNING";
export const CLEAN_AVATAR_AUTHENTICATION_WARNING="CLEAN_AVATAR_AUTHENTICATION_WARNING";

// General Constants 
export const SUCCESSFULL_EDIT="SUCCESSFULL_EDIT";
export const SUCCESSFULL_NEW="SUCCESSFULL_NEW";
export const SUCCESSFUL_DELETE="SUCCESSFUL_DELETE";
export const SUCCESSFUL_SEND="SUCCESSFUL_SEND";
export const ERROR_REQUIRED_FIELDS="ERROR_REQUIRED_FIELDS";
export const SUCCESS_REQUIRED_FIELDS="SUCCESS_REQUIRED_FIELDS";
export const COUNTRY_LIST="COUNTRY_LIST";
export const DISMATCH_PASSWORD= "DISMATCH_PASSWORD";
export const DELETE_SUCCESSFUL ="DELETE_SUCCESSFUL";
export const SUCCESSFULL_EDIT_CLEAN="SUCCESSFULL_EDIT_CLEAN";
export const SUCCESSFUL_SEND_CLEAN="SUCCESSFUL_SEND_CLEAN";
export const UPDATE_FILE_NAME="UPDATE_FILE_NAME";
export const DELETE_ALERT="DELETE_ALERT";
export const REDIRECT_GROUP= "REDIRECT_GROUP";
export const SUCCESSFULL_REDIRECT="SUCCESSFULL_REDIRECT";
export const UPDATE_TAB="UPDATE_TAB";
export const SUCCESSFUL_NEW_ARRAY="SUCCESSFUL_NEW_ARRAY";
export const CHANGE_ACTIVE_TAB="CHANGE_ACTIVE_TAB";
export const CHANGE_VERTICAL_ACTIVE_TAB="CHANGE_VERTICAL_ACTIVE_TAB";
export const IDLE_TIMER="IDLE_TIMER";
export const SUCCESS_SUBMIT="SUCCESS_SUBMIT";
export const ERROR_SUBMIT="ERROR_SUBMIT";
export const ERROR_EVALUATION="ERROR_EVALUATION";
export const SHOW_PROFILE_UPDATE="SHOW_PROFILE_UPDATE";
export const CLEAR_PROFILE_UPDATE="CLEAR_PROFILE_UPDATE";
export const DELETE_WARNING="DELETE_WARNING";
export const ACTION_WARNING="ACTION_WARNING";
export const GROUP_DELETE_WARNING="GROUP_DELETE_WARNING";
export const SUCCESSFUL_LOAD = "SUCCESSFUL_LOAD";
export const LOGGIN_IN = "LOGGIN_IN";
export const RESPONSE_OBTAINED = "RESPONSE_OBTAINED";
export const SWITCH_BASE_URL = "SWITCH_BASE_URL";
export const SUCCESSFUL_CONNECTION = "SUCCESSFUL_CONNECTION";
export const FAILED_CONNECTION = "FAILED_CONNECTION";
export const CONNECTION_TEST = "CONNECTION_TEST";
export const UPDATE_NAVPILLS_TAB = "UPDATE_NAVPILLS_TAB";
export const UPDATE_WIZARD_TAB = "UPDATE_WIZARD_TAB";
export const DUPLICATE_ERROR_EVALUATION = "DUPLICATE_ERROR_EVALUATION";
export const SUCCESSFUL_SAVE = "SUCCESSFUL_SAVE";
export const SAVED_LINK = "SAVED_LINK";
export const DELETE_SAVED_LINK = "DELETE_SAVED_LINK";
export const WAITING_RESPONSE = "WAITING_RESPONSE";
export const LOADING_SEARCH = "LOADING_SEARCH";
export const CLEAN_LOADING_SEARCH = "CLEAN_LOADING_SEARCH";
export const RESET_WAITING_RESPONSE = "RESET_WAITING_RESPONSE";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const CLEAN_SESSION_EXPIRED = "CLEAN_SESSION_EXPIRED";

// Select Constans 
export const UPDATE_LANGUAGE_SELECT="UPDATE_LANGUAGE_SELECT";
export const UPDATE_PROGRAM_SELECT="UPDATE_PROGRAM_SELECT";
export const UPDATE_MODALITY_SELECT="UPDATE_MODALITY_SELECT";
export const UPDATE_ROLE_SELECT="UPDATE_ROLE_SELECT";
export const UPDATE_COUNTRY_SELECT="UPDATE_COUNTRY_SELECT";
export const UPDATE_AMBASSADOR_SELECT="UPDATE_AMBASSADOR_SELECT";
export const UPDATE_QUALITY1_SELECT="UPDATE_QUALITY1_SELECT";
export const UPDATE_ACTIVE_SELECT="UPDATE_ACTIVE_SELECT";
export const UPDATE_TYPE_SELECT="UPDATE_TYPE_SELECT";
export const UPDATE_YES_NOT_SELECT="UPDATE_YES_NOT_SELECT";
export const UPDATE_CATEGORY_SELECT="UPDATE_CATEGORY_SELECT";
export const UPDATE_STATE_SELECT="UPDATE_STATE_SELECT";
export const UPDATE_UNIT_SELECT="UPDATE_UNIT_SELECT";
export const UPDATE_ANSWER_TYPE_SELECT="UPDATE_ANSWER_TYPE_SELECT";
export const UPDATE_REQUIREMENT_SELECT="UPDATE_REQUIREMENT_SELECT";
export const UPDATE_STATE_PENDING_SELECT="UPDATE_STATE_PENDING_SELECT";

// Constants of Group
export const GROUP_LIST="GROUP_LIST";
export const LOAD_FORM_GROUP="LOAD_FORM_GROUP";
export const SHOW_GROUP="SHOW_GROUP";
export const EDIT_GROUP="EDIT_GROUP";
export const NEW_GROUP="NEW_GROUP";
export const DELETE_GROUP="DELETE_GROUP";
export const DEFAULT_FORM_GROUP="DEFAULT_FORM_GROUP";
export const GET_AMBASSADOR_ID="GET_AMBASSADOR_ID";
export const GET_PROJECT_PROGRESS="GET_PROJECT_PROGRESS";
export const MBS_IMAGE_ALERT="MBS_IMAGE_ALERT";
export const UPLOAD_IMAGE="UPLOAD_IMAGE";
export const UPLOAD_SA_FILE="UPLOAD_SA_FILE";
export const SA_FILE_ALERT="SA_FILE_ALERT";
export const DELETE_IMAGE_ALERT="DELETE_IMAGE_ALERT";
export const GROUP_PROGRAM="GROUP_PROGRAM";
export const UPDATE_GROUP_PAGINATION="UPDATE_GROUP_PAGINATION";
export const LOADING_GROUP = "LOADING_GROUP";
export const PAGINATED_GROUP_LIST = "PAGINATED_GROUP_LIST";
export const GROUP_CUSTOMIZED_URL = "DATA_CUSTOMIZED_URL";
export const CLEAR_GROUP_CUSTOMIZED_URL = "CLEAR_DATA_CUSTOMIZED_URL";

//constant of introduction
export const LIST_INTRODUCTION="LIST_INTRODUCTION";
export const NEW_INTRODUCTION="NEW_INTRODUCTION";
export const EDIT_INTRODUCTION="EDIT_INTRODUCTION";
export const SHOW_INTRODUCTION="SHOW_INTRODUCTION";
export const LOAD_FORM_INTRODUCTION="LOAD_FORM_INTRODUCTION";
export const DELELTE_INTRODUCTION="DELETE_INTRODUCTION";

// Constants of Ambassador 
export const AMBASSADOR_LIST = "AMBASSADOR_LIST";
export const SHOW_AMBASSADOR="SHOW_AMBASSADOR";
export const LOAD_FORM_AMBASSADOR="LOAD_FORM_AMBASSADOR";
export const EDIT_AMBASSADOR="EDIT_AMBASSADOR";
export const ERROR_EDIT_AMBASSADOR="ERROR_EDIT_AMBASSADOR";
export const NEW_AMBASSADOR="NEW_AMBASSADOR";
export const EDIT_PASSWORD_AMBASSADOR="EDIT_PASSWORD_AMBASSADOR";
export const DELETE_AMBASSADOR="DELETE_AMBASSADOR";
export const AMBASSADOR_CONTRACT="AMBASSADOR_CONTRACT";
export const PAGINATED_AMBASSADOR_LIST="PAGINATED_AMBASSADOR_LIST";
export const UPDATE_AMBASSADOR_PAGINATION="UPDATE_GROUP_PAGINATION";
export const LOADING_AMBASSADOR="LOADING_AMBASSADOR";
export const GET_AMBASSADOR_STATE="GET_AMBASSADOR_STATE";
export const PAGINATED_AMBASSADOR_GALLERY = "PAGINATED_AMBASSADOR_GALLERY";
export const GALLERY_LOADING = "GALLERY_LOADING";
export const AMBASSADOR_LEVEL = "AMBASSADOR_LEVEL";
export const AMBASSADOR_RECOGNITION_LEVEL = "AMBASSADOR_RECOGNITION_LEVEL";
export const PAGINATED_AMBASSADOR_STATS_LIST="PAGINATED_AMBASSADOR_STATS_LIST";
export const STATS_LOADING = "STATS_LOADING";

// Constants of Certificate 
export const GET_CERTIFICATE_LIST= "GET_CERTIFICATE_LIST";
export const APPROVE_LIST="APPROVE_LIST";

// Constants of Dashboard
export const STUDENT_MBS_LIST = "STUDENT_MBS_LIST";
export const STUDENT_AMBASSADOR_LIST = "STUDENT_AMBASSADOR_LIST";
export const FUTURE_AMBASSADOR_LIST = "FUTURE_AMBASSADOR_LIST";
export const DASHBOARD_STUDENT = "DASHBOARD_STUDENT";
export const AMBASSADOR_STUDENT_LIST="AMBASSADOR_STUDENT_LIST";
export const STUDENT_EXPRESS_LIST = "STUDENT_EXPRESS_LIST";

//Constants of Reports
export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORT_COUNTRY = "GET_REPORT_COUNTRY";
export const GET_REPORT_AMBASSADOR = "GET_REPORT_AMBASSADOR";
export const GET_AMBASSADOR_COUNTRY = "GET_AMBASSADOR_COUNTRY";
export const AMBASSADOR_REPORT = "AMBASSADOR_REPORT";
export const GET_REPORT_GLOBAL_MAP = "GET_REPORT_GLOBAL_MAP";
export const GET_GLOBAL_NUMBERS = "GET_GLOBAL_NUMBERS";
export const AMBASSADOR_STATISTICS = "AMBASSADOR_STATISTICS";
export const AMBASSADOR_GROUP_REPORTS = "AMBASSADOR_GROUP_REPORTS";
export const STATISTICS_GLOBAL_AMBASSADOR = "STATISTICS_GLOBAL_AMBASSADOR"
export const LAST_YEAR_GRADUATES_REPORT = "LAST_YEAR_GRADUATES_REPORT";
export const LAST_SIX_MONTHS_GRADUATES_REPORT = "LAST_SIX_MONTHS_GRADUATES_REPORT";
export const LAST_MONTH_GRADUATES_REPORT = "LAST_MONTH_GRADUATES_REPORT";
export const DATE_RANGE_GRADUATES_REPORT = "DATE_RANGE_GRADUATES_REPORT";
export const LAST_YEAR_SA_GRADUATES_REPORT = "LAST_YEAR_SA_GRADUATES_REPORT";
export const LAST_SIX_MONTHS_SA_GRADUATES_REPORT = "LAST_SIX_MONTHS_SA_GRADUATES_REPORT";
export const LAST_MONTH_SA_GRADUATES_REPORT = "LAST_MONTH_SA_GRADUATES_REPORT";
export const DATE_RANGE_SA_GRADUATES_REPORT = "DATE_RANGE_SA_GRADUATES_REPORT";
export const GET_MBS_GRADUATES_BY_LANGUAGE = "GET_MBS_GRADUATES_BY_LANGUAGE";
export const CHANGE_ACTIVE_TAB_REPORTS = "CHANGE_ACTIVE_TAB_REPORTS";
export const GET_REPORT_MODALITY_MAP = "GET_REPORT_MODALITY_MAP";
export const LAST_YEAR_WELLNESS_GRADUATES_REPORT = "LAST_YEAR_WELLNESS_GRADUATES_REPORT";
export const LAST_SIX_MONTHS_WELLNESS_GRADUATES_REPORT = "LAST_SIX_MONTHS_WELLNESS_GRADUATES_REPORT";
export const LAST_MONTH_WELLNESS_GRADUATES_REPORT = "LAST_MONTH_WELLNESS_GRADUATES_REPORT";
export const DATE_RANGE_WELLNESS_GRADUATES_REPORT = "DATE_RANGE_WELLNESS_GRADUATES_REPORT";
export const LAST_YEAR_WELLNESS_PARTICIPANT_GRADUATES_REPORT = "LAST_YEAR_WELLNESS_PARTICIPANT_GRADUATES_REPORT";
export const LAST_SIX_MONTHS_WELLNESS_PARTICIPANT_GRADUATES_REPORT = "LAST_SIX_MONTHS_WELLNESS_PARTICIPANT_GRADUATES_REPORT";
export const LAST_MONTH_WELLNESS_PARTICIPANT_GRADUATES_REPORT = "LAST_MONTH_WELLNESS_PARTICIPANT_GRADUATES_REPORT";
export const DATE_RANGE_WELLNESS_PARTICIPANT_GRADUATES_REPORT = "DATE_RANGE_WELLNESS_PARTICIPANT_GRADUATES_REPORT";

//Constants of Code
export const CODE_LIST="CODE_LIST";
export const SHOW_CODE="SHOW_CODE";
export const LOAD_FORM_CODE="LOAD_FORM_CODE";
export const EDIT_CODE="EDIT_CODE";
export const ERROR_EDIT_CODE="ERROR_EDIT_CODE";
export const DELETE_CODE="DELETE_CODE";

//Constants of Administrator
export const ADMINISTRATOR_LIST="ADMINISTRATOR_LIST";
export const ADMINLANGUAGE_LIST="ADMINLANGUAGE_LIST";
export const NEW_ADMINISTRATOR="NEW_ADMINISTRATOR";
export const SHOW_ADMINISTRATOR="SHOW_ADMINISTRATOR";
export const LOAD_FORM_ADMINISTRATOR="LOAD_FORM_ADMINISTRATOR";
export const EDIT_ADMINISTRATOR="EDIT_ADMINISTRATOR";
export const ERROR_EDIT_ADMINISTRATOR="ERROR_EDIT_ADMINISTRATOR";
export const EDIT_PASSWORD_ADMINISTRATOR="EDIT_PASSWORD_ADMINISTRATOR";
export const DELETE_ADMINISTRATOR = "DELETE_ADMINISTRATOR";
export const PROJECT_QUALIFIER_LIST = "PROJECT_QUALIFIER_LIST";

//Constants of Evaluation
export const EVALUATION_PRE="EVALUATION_PRE";
export const EVALUATION_POST="EVALUATION_POST";
export const PRE_ALERT="PRE_ALERT";
export const POST_ALERT="POST_ALERT";
export const SHOW_EVALUATION="SHOW_EVALUATION";
export const SHOW_WELLNESS_EVALUATION="SHOW_WELLNESS_EVALUATION";
export const SHOW_FOLLOW_UP_EVALUATION="SHOW_FOLLOW_UP_EVALUATION";
export const LOAD_FORM_EVALUATION="LOAD_FORM_EVALUATION";

// Constants of Grant
export const GRANT_LIST="GRANT_LIST";
export const LOAD_FORM_GRANT="LOAD_FORM_GRANT";
export const SHOW_GRANT="SHOW_GRANT";
export const EDIT_GRANT="EDIT_GRANT";
export const NEW_GRANT="NEW_GRANT";
export const DEFAULT_FORM_GRANT="DEFAULT_FORM_GRANT";
export const NEW_GRANT_UPDATE="NEW_GRANT_UPDATE";
export const DELETE_GRANT="DELETE_GRANT";
export const SHOW_GRANT_UPDATE="SHOW_GRANT_UPDATE";
export const GRANT_ACTIVE_LIST="GRANT_ACTIVE_LIST";
export const NEW_GRANT_AMBASSADOR="NEW_GRANT_AMBASSADOR";
export const EDIT_GRANT_AMBASSADOR="EDIT_GRANT_AMBASSADOR";
export const SHOW_GRANT_AMBASSADOR="SHOW_GRANT_AMBASSADOR";
export const LOAD_FORM_GRANT_AMBASSADOR="LOAD_FORM_GRANT_AMBASSADOR";
export const SEND_REVISION_GRANT_AMBASSADOR="SEND_REVISION_GRANT_AMBASSADOR";
export const SEND_CORRECTION_GRANT_AMBASSADOR="SEND_CORRECTION_GRANT_AMBASSADOR";
export const SEND_REJECT_GRANT_AMBASSADOR="SEND_REJECT_GRANT_AMBASSADOR";
export const SEND_APPROVED_GRANT_AMBASSADOR="SEND_APPROVED_GRANT_AMBASSADOR";
export const GRANT_AMBASSADOR_LIST="GRANT_AMBASSADOR_LIST";
export const GRANT_AMBASSADOR_APPLICATION="GRANT_AMBASSADOR_APPLICATION";
export const SHOW_GRANT_DEADLINE="SHOW_GRANT_DEADLINE";
export const NEW_GRANT_GROUP="NEW_GRANT_GROUP";
export const SHOW_GRANT_GROUP_LIST="SHOW_GRANT_GROUP_LIST";
export const LOAD_FORM_GRANT_GROUP="LOAD_FORM_GRANT_GROUP";
export const SHOW_GRANT_USER="SHOW_GRANT_USER";
export const SHOW_GRANT_STATISTIC="SHOW_GRANT_STATISTIC";
export const CLEAN_NEW_GRANT_AMBASSADOR="CLEAN_NEW_GRANT_AMBASSADOR";
export const SHOW_LIST_GRANT_AMBASSADOR="SHOW_LIST_GRANT_AMBASSADOR";
export const SHOW_GRANT_GRADUATES="SHOW_GRANT_GRADUATES";
export const MENTORED_AMBASSADOR_LIST="MENTORED_AMBASSADOR_LIST";
export const MENTORED_AMBASSADOR="MENTORED_AMBASSADOR";
export const SHOW_REQUIREMENTS="SHOW_REQUIREMENTS";
export const SHOW_APPROVED_APPLICATIONS="SHOW_APPROVED_APPLICATIONS";
export const REDIRECT_GRANT="REDIRECT_GRANT";
export const SHOW_REJECTED_APPLICATIONS="SHOW_REJECTED_APPLICATIONS";
export const GRANT_REQUIREMENT_LIST="GRANT_REQUIREMENT_LIST";
export const SHOW_GRANT_REQUIREMENT="SHOW_GRANT_REQUIREMENT";
export const LOAD_FORM_GRANT_REQUIREMENT="LOAD_FORM_GRANT_REQUIREMENT";
export const GRANTMEMBER_LIST="GRANTMEMBER_LIST";
export const CLEAN_GRANTMEMBER_LIST="CLEAN_GRANTMEMBER_LIST";
export const CLEAN_SHOW_GRANT_AMBASSADOR="CLEAN_SHOW_GRANT_AMBASSADOR";
export const ERROR_ADD_GRANTMEMBER="ERROR_ADD_GRANTMEMBER";
export const MEMBER_ALREADY_ADDED="MEMBER_ALREADY_ADDED";
export const CLEAN_ADD_MEMBER_WARNING="CLEAN_ADD_MEMBER_WARNING";
export const SHOW_AMBASSADOR_RECOGNITION="SHOW_AMBASSADOR_RECOGNITION";

// Timeline constants
export const SHOW_TIMELINE_PROFILE="SHOW_TIMELINE_PROFILE";
export const NEW_TIMELINE_PROFILE="NEW_TIMELINE_PROFILE";
export const DELETE_TIMELINE_PROFILE="DELETE_TIMELINE_PROFILE";

// Course Constants
export const NEW_COURSE="NEW_COURSE";
export const SHOW_COURSE="SHOW_COURSE";
export const EDIT_COURSE="EDIT_COURSE";
export const DELETE_COURSE="DELETE_COURSE";
export const LIST_COURSE="LIST_COURSE";
export const SUCCESSFULL_NEW_COURSE='SUCCESSFULL_NEW_COURSE';
export const FAILED_NEW_COURSE='FAILED_NEW_COURSE';
export const SAVE_COURSE = 'SAVE_COURSE';
export const COURSE_LIST='COURSE_LIST';
export const LOAD_FORM_COURSE="LOAD_FORM_COURSE";
export const CONTENT_LIST="CONTENT_LIST";
export const COURSE_VIEW_STUDENT="COURSE_VIEW_STUDENT";
export const COURSE_VIEW_EDITOR="COURSE_VIEW_EDITOR";
export const CHANGE_SUCCESSFUL = "CHANGE_SUCCESSFUL"; 
export const STUDENT_LIST_COURSE = "STUDENT_LIST_COURSE";
export const ANSWER_LIST = "ANSWER_LIST"; 
export const SHOW_ANSWER = "SHOW_ANSWER";
export const SUGGESTION_WARNING = "SUGGESTION_WARNING";
export const PROGRESS_STATE_LIST = "PROGRESS_STATE_LIST";
export const PROGRESS_SHOW_NEXT_PENDING = "PROGRESS_SHOW_NEXT_PENDING";
export const SHOW_PROGRESS_PENDING_CORRECTION = "SHOW_PROGRESS_PENDING_CORRECTION";
export const COURSE_PAYMENT = "COURSE_PAYMENT";
export const SHOW_PAYMENT = "SHOW_PAYMENT";
export const SUCCESSFUL_TRANSFER_STUDENTS = "SUCCESSFUL_TRANSFER_STUDENTS";
export const WAITING_STUDENTS_TRANSFER = "WAITING_STUDENTS_TRANSFER";
export const STUDENTS_TRANSFER_COMPLETED = "STUDENTS_TRANSFER_COMPLETED";
export const COURSE_PUBLISHED = "COURSE_PUBLISHED";
export const THEREIS_COURSE_PUBLISHED = "THEREIS_COURSE_PUBLISHED";

//Wellness Constants
export const NEW_WELLNESS = "NEW_WELLNESS";
export const SHOW_WELLNESS = "SHOW_WELLNESS";
export const LOAD_FORM_WELLNESS = "LOAD_FORM_WELLNESS";
export const SUCCESSFUL_SEND_REVISION_WELLNESS = "SUCCESSFULL_SEND_REVISION_WELLNESS";
export const ERROR_SEND_REVISION_WELLNESS = "ERROR_SEND_REVISION_WELLNESS";
export const SUCCESSFUL_APPROVE_WELLNESS = "SUCCESSFUL_APPROVE_WELLNESS";
export const ERROR_APPROVE_WELLNESS = "ERROR_APPROVE_WELLNESS";
export const WELLNESS_PROGRESS = "WELLNESS_PROGRESS";
export const PAGINATED_WELLNESS_LIST = "PAGINATED_WELLNESS_LIST";
export const LOADING_WELLNESS = "LOADING_WELLNESS";
export const RESET_PAGINATED_WELLNESS_LIST = "RESET_PAGINATED_WELLNESS_LIST";
export const STUDENT_WELLNESS_LIST = "STUDENT_WELLNESS_LIST";
export const STUDENT_WELLNESS_PARTICIPANT_LIST = "STUDENT_WELLNESS_PARTICIPANT_LIST";
export const SHOW_WELLNESS_PARTICIPANT = "SHOW_WELLNESS_PARTICIPANT";

//Unit Constants
export const NEW_UNIT="NEW_UNIT";
export const SHOW_UNIT="SHOW_UNIT";
export const DELETE_UNIT="DELETE_UNIT";
export const EDIT_UNIT = "EDIT_UNIT";
export const LOAD_FORM_UNIT="LOAD_FORM_UNIT";
export const UNIT_LIST="UNIT_LIST";
export const UNIT_LIST_PUBLISHED = "UNIT_LIST_PUBLISHED";
export const UPDATE_UNITLIST_SELECT="UNIT_LIST_SELECT";
export const UPDATE_UNIT_TREE="UPDATE_UNIT_TREE";
export const UPDATE_UNIT_ID="UPDATE_UNIT_ID";
export const CHANGE_UNIT_NAME_WARNING = "CHANGE_UNIT_NAME_WARNING";
export const WAITING_PRINCIPLE_LOAD = "WAITING_PRINCIPLE_LOAD";
export const RESET_WAITING_PRINCIPLE_LOAD = "RESET_WAITING_PRINCIPLE_LOAD";

//Principle Constants
export const NEW_PRINCIPLE="NEW_PRINCIPLE"; 
export const SHOW_PRINCIPLE="SHOW_PRINCIPLE"; 
export const DELETE_PRINCIPLE="DELETE_PRINCIPLE";
export const EDIT_PRINCIPLE="EDIT_PRINCIPLE";
export const LOAD_FORM_PRINCIPLE="LOAD_FORM_PRINCIPLE";
export const UPDATE_PRINCIPLE_ID="UPDATE_PRINCIPLE_ID";
export const CHANGE_NEXT_PRINCIPLE="CHANGE_NEXT_PRINCIPLE";
export const CHANGE_PRINCIPLE_NAME_WARNING = "CHANGE_PRINCIPLE_NAME_WARNING";

//Stop Constants
export const NEW_STOP="NEW_STOP";
export const SHOW_STOP="SHOW_STOP";
export const RESET_SHOW_STOP="RESET_SHOW_STOP";
export const EDIT_STOP="EDIT_STOP";
export const STOP_LIST = "STOP_LIST";
export const STOP_STATE_LIST = "STOP_STATE_LIST";
export const COURSE_STUDENT_STOP_LIST = "COURSE_STUDENT_STOP_LIST";

// Test Consntants

export const NEW_TEST="NEW_TEST";
export const SHOW_TEST="SHOW_TEST";
export const EDIT_TEST="EDIT_TEST";
export const DELETE_TEST="DELETE_TEST";
export const LIST_TEST="LIST_TEST";
export const LOAD_FORM_TEST="LOAD_FORM_TEST";

// Transfer
export const NEW_TRANSFER="NEW_TRANSFER";
export const SHOW_TRANSFER="SHOW_TRANSFER";
export const EDIT_TRANSFER="EDIT_TRANSFER";
export const ERROR_EDIT_TRANSFER="ERROR_EDIT_TRANSFER";
export const LOAD_FORM_TRANSFER="LOAD_FORM_TRANSFER";

// Resources
export const NEW_RESOURCE="NEW_RESOURCE";
export const EDIT_RESOURCE="EDIT_RESOURCE";
export const SHOW_RESOURCE="SHOW_RESOURCE";
export const LOAD_FORM_RESOURCE="LOAD_FORM_RESOURCE";
export const LIST_RESOURCE="LIST_RESOURCE";
export const NEW_CATEGORY="NEW_CATEGORY";
export const EDIT_CATEGORY="EDIT_CATEGORY";
export const SHOW_CATEGORY="SHOW_CATEGORY";
export const LOAD_FORM_CATEGORY="LOAD_FORM_CATEGORY";
export const LIST_CATEGORY="LIST_CATEGORY";
export const UPDATE_CATEGORYLIST_SELECT="CATEGORYLIST_SELECT";
export const LIST_SUBCATEGORY="LIST_SUBCATEGORY"
export const UPDATE_SUBCATEGORYLIST_SELECT="SUBCATEGORYLIST_SELECT";
export const NEW_SUBCATEGORY="NEW_SUBCATEGORY";
export const EDIT_SUBCATEGORY="EDIT_SUBCATEGORY";
export const SHOW_SUBCATEGORY="SHOW_SUBCATEGORY";
export const LOAD_FORM_SUBCATEGORY="LOAD_FORM_SUBCATEGORY";
export const LIST_LANGUAGE_CATEGORY="LIST_LANGUAGE_CATEGORY";
export const LIST_RESOURCE_SUBCATEGORY="LIST_RESOURCE_SUBCATEGORY";

//Translation 
export const TRANSLATION_LANGUAGE_LIST="TRANSLATION_LANGUAGE_LIST";
export const GENERAL_TRANSLATION_LIST="GENERAL_TRANSLATION_LIST";
export const NEW_TRANSLATION="NEW_TRANSLATION";
export const EDIT_TRANSLATION="EDIT_TRANSLATION";
export const SHOW_TRANSLATION="SHOW_TRANSLATION";
export const RESET_SHOW_TRANSLATION="RESET_SHOW_TRANSLATION";
export const LOAD_FORM_TRANSLATION="LOAD_FORM_TRANSLATION";
export const SHOW_NEXT_PENDING="SHOW_NEXT_PENDING";

//Tutorials
export const LIST_TUTORIAL="LIST_TUTORIAL";
export const SHOW_TUTORIAL="SHOW_TUTORIAL";
export const NEW_TUTORIAL="NEW_TUTORIAL";
export const EDIT_TUTORIAL="EDIT_TUTORIAL";
export const LOAD_FORM_TUTORIAL="LOAD_FORM_TUTORIAL";
export const LIST_SECTION="LIST_SECTION";
export const SHOW_SECTION="SHOW_SECTION";
export const NEW_SECTION="NEW_SECTION";
export const EDIT_SECTION="EDIT_SECTION";
export const LOAD_FORM_SECTION="LOAD_FORM_SECTION";
export const LIST_AVAILABLE_SECTION="LIST_AVAILABLE_SECTION";
export const LIST_AVAILABLE_TUTORIAL="LIST_AVAILABLE_TUTORIAL";
export const LIST_AVAILABLE_TUTORIAL_KEY="LIST_AVAILABLE_TUTORIAL_KEY";
export const SET_TUTORIAL_KEYWORD="TUTORIAL_KEYWORD";
export const CLEAN_SHOW_TUTORIAL="CLEAN_SHOW_TUTORIAL";
export const LIST_SECTION_LANGUAGE="LIST_SECTION_LANGUAGE";

//Payment
export const LIST_PAYMENT="LIST_PAYMENT";

//Tracking 
export const LIST_TRACKING="LIST_TRACKING";
export const SHOW_TRACKING="SHOW_TRACKING";